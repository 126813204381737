import React from 'react';
import newImage from  '../asets/Join-our-Club-black-3.png'
import lockImage from  '../asets/lock.jpg'
import Toast from "../Tost";


const NewsletterSection = () => {

    const [email, setEmail] = React.useState("");

    const emailSubscribe = (e) => {
        e.preventDefault();
        if (email) {
            Toast(`Subscribed Successfully with ${email}`, "success");
            setEmail("");
        } else {
            Toast("Please enter a valid email address", "error");
        }
    };



    return (
        <div style={{ backgroundColor: " #b8242a"}}>
        <div className="container py-5 text-white">
            <div className="row justify-content-center bg-white text-dark rounded-4 ">
                <div className="col-lg-6 p-5">
                    <h2>Our Newsletter</h2>
                    <p>Get Latest Updates and Offers.</p>
                    <ul className="list-unstyled">
                        <li>✔️ Savings on consultations</li>
                        <li>✔️ Information about oral health</li>
                        <li>✔️ and much more!</li>
                    </ul>
                    <div className="input-group mt-3">
                        <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter your email address" />
                        <button className="btn btn-dark" onClick={emailSubscribe}>Subscribe</button>
                    </div>
                    <p className="mt-3 text-dark small d-flex align-items-center">
                        <img src={lockImage} alt="Lock Icon" width="32" className="me-2" />
                        By signing up, you agree to receive emails from our business. We value your privacy and will never share your information!
                    </p>
                </div>
                <div className="col-lg-6 text-center">
                    <img src={newImage} alt="Person holding a phone" className="img-fluid" />
                </div>
            </div>
        </div>
        </div>
    );
};

export default NewsletterSection;
