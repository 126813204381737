import Card from "./Card";
const CardSection = () => {
    const cardData = [
      {
        img: "./SERVICE3.jpg",
        title: "Protection Level",
        text: "Consider the type of protection you need against scratches, chips, UV rays, and environmental factors."
      },
      {
        img: "./SERVICE2.jpg",
        title: "Thickness",
        text: "Thicker PPF offers superior durability and impact resistance, making it ideal for high-traffic areas on your car."
      },
      {
        img: "./SEVICE1.jpeg",
        title: "Warranty and Lifespan",
        text: "Choose PPF with a long warranty and expected lifespan to ensure reliable, long-term protection for your vehicle."
      },
      {
        img: "./SRVI4.jpg",
        title: "Application Quality",
        text: "Proper installation by experts ensures a smooth, bubble-free finish for maximum protection and aesthetic appeal."
      }
    ];
  
    return (
      <div className="container-fluid text-white py-5" style={{ backgroundImage: 'url(./design.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="container text-center">
          <h2>4 Key Factors to Consider When Choosing PPF</h2>
          <p className="text-white">
            When selecting Paint Protection Film (PPF), it's essential to consider factors like protection level,
            thickness, warranty, and application quality. These elements ensure long-lasting defense for your vehicle's
            paint, maintaining its appearance and value over time.
          </p>
          <div className="row mt-4">
            {cardData.map((card, index) => (
              <Card key={index} img={card.img} title={card.title} text={card.text} />
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default CardSection;
  