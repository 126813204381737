import React from 'react'
import Card from './Card';

const HowWorkSection = () => {
    const cardData = [
        {
          img: "https://acko-cms.ackoassets.com/Car_Service_history_3_82b5c6881a.png",
          title: "Book Your Service",
          text: "Easily book your car by just clicking on the 'Book Now' button and get your car serviced by experienced and certified technicians using only genuine parts and products."
        },
        {
          img: "https://5.imimg.com/data5/UC/IA/BS/GLADMIN-4767699/selection-069.png",
          title: "Schedule Free Pickup",
          text: "We know your time is valuable, so we offer a free pickup and drop service for all our car services at your convenience."
        },
        {
          img: "https://qodenext.com/wp-content/uploads/2024/04/how-does-a-vehicle-tracking-system-work-6614f3a81f6f9.webp",
          title: "Track Service Status",
          text: "We understand that you want to know what is happening with your car while it is being serviced. That's why we offer a real-time tracking system that allows you to see the status of your service at any time."
        }
      ];
    
      return (
        <div style={{ backgroundColor: " #b8242a"}}>
        <div className="container-fluid py-5">
          <div className="container text-center">
            <h2 className='text-white'>How do we work?</h2>
            <p className='text-white'>
              At Caross, we provide a seamless process for applying high-quality Paint Protection Film (PPF)
              to your vehicle, ensuring lasting protection and a flawless finish.
            </p>
            <div className="row mt-4">
              {cardData.map((card, index) => (
                <Card key={index} img={card.img} title={card.title} text={card.text} />
              ))}
            </div>
            <a href="https://caross.in/services/car-paint-protection" target="_blank" rel="noreferrer">
            <button className="btn btn-danger mt-4 fs-5">Book Now</button>
            </a>
          </div>
        </div>
        </div>
      );
    };

export default HowWorkSection
