import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const services = [
  {
    title: "Car Detailing Services",
    description:
      "Car detailing services provide deep cleaning and restoration for your vehicle, enhancing its appearance and protecting it from wear.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVrjtkGQtC1futyO_aTFoU1eZPGTaZwAqir4OvrkrijFp84Ym-UjzHGHRpoKYI06kbDEA&usqp=CAU",
  },
  {
    title: "Ceramic Coating",
    description:
      "Ceramic coating is a protective layer applied to your car's paint, offering a durable shield against dirt, UV rays, and minor scratches.",
    image:
      "https://thumbs.dreamstime.com/b/worker-uniform-disassembles-vehicle-engine-car-service-station-automobile-checking-inspection-professional-diagnostics-173424972.jpg",
  },
  {
    title: "Denting & Painting Services",
    description:
      "Denting and painting services restore your car's exterior to its original look by repairing dents and applying a fresh coat of paint.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwlZ2G3bo_jEUV5sjWfpelx5fUSnr-arSxrASfQ7Lm-fU8_4wdcBcQtAgS1Y-jg8r4s0s&usqp=CAU",
  },
  {
    title: "Car Services",
    description:
      "Comprehensive car services ensure your vehicle stays in top condition, covering everything from routine maintenance and repairs to cleaning and detailing.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJPIPp4Mc1XGY_mEfYbMqY-AFui3yMHqizI6kMxQnSykDLjxTrNLlQfSxDkvenah1TPdk&usqp=CAU",
  },
  {
    title: "Car Accessories",
    description:
      "Enhance your driving experience with premium car accessories designed for comfort, convenience, and style.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-q6rPc5rNXgzRLIVusp7R1ALNX8BeYj3QOnoMMBGZKngeXeS4zbKOLFroY0O6QZs-nQo&usqp=CAU",
  },
];

const ServicesSection = () => {
  return (
    <section className="py-5 bg-light">
      <div className="container text-center">
        <h2 className="mb-5 text-danger">Other Services</h2>
        <div className="row gy-5 justify-content-start">
          {services.map((service, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
              <div className="card shadow-sm text-center p-4 w-100" style={{ maxWidth: "350px" }}>
                {/* Circular Image Positioned on Top */}
                <div className="mx-auto rounded-circle border border-secondary overflow-hidden" style={{ width: "100px", height: "100px", marginTop: "-50px", backgroundColor: "#fff" }}>
                  <img
                    src={service.image}
                    alt={service.title}
                    className="img-fluid"
                    style={{ objectFit: "cover" , width: "100%", height: "100%" }}
                  />
                </div>
                <div className="card-body text-dark mt-3">
                  <h5 className="card-title text-danger">{service.title}</h5>
                  <p className="card-text">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
