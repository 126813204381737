import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
// import Navbar from "./components/Navbar";
import Homepage from "./page/homepage/Homepage";
import Login from "./page/auth-pages/Login";
import Services from "./page/services/Services";
import EnterOTP from "./page/auth-pages/enter-otp/EnterOTP";
import AddNewCar from "./page/services/add-car/AddNewCar";
import ServiceCard from "./components/ServiceCard";
import AddedCart from "./page/added-cart/AddedCart";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MyAccount from "./page/my-account/MyAccount";
import EditDetails from "./page/my-account/EditDetails";
import MyCars from "./page/my-account/MyCars";
import MyOrders from "./page/my-account/MyOrders";
import UserAddress from "./page/my-account/user-address/UserAddress";
import PrivateRoute from "./PrivateRoutes";
import ScrollToTop from "./ScrollToTop";
import ContactUs from "./page/footer-page/ContactUs";
import AboutUs from "./page/footer-page/AboutUs";
import Blog from "./page/footer-page/Blog";
import InsPection from "./page/footer-page/InsPection";
import Parking from "./page/footer-page/Parking";
import Partners from "./page/footer-page/Partners";
import PreDelivery from "./page/footer-page/PreDelivery";
import Rsa from "./page/footer-page/Rsa";
import Locator from "./page/footer-page/Locator";
import Workshop from "./page/footer-page/Workshop";
import EnquiryForFrenchise from "./page/footer-page/EnquiryForFrenchise";
import CancellationAndRefundPolicy from "./page/footer-page/CancellationAndRefundPolicy";
import ShippingAndDeliveryPolicy from "./page/footer-page/ShippingAndDeliveryPolicy";
import TermAndCondition from "./page/footer-page/TermAndCondition";
import PrivacyPolicy from "./page/footer-page/PrivacyPolicy";
// import MyOrderChild from "./page/my-account/MyOrderChild";
import HandleNavbar from "./components/HandleNavbar";
import FloatingCart from "./components/FloatingCart";
import MyCoupons from "./page/my-account/my-coupons/MyCoupons";
import Maintenance from "./page/maintenance/Maintenance";
import OrderPlacedSuccessfully from "./OrderPlacedSuccessfully";
// import LeadGeneration from "./page/lead-generation/LeadGeneration";
import ThankYou from "./page/thank-you/ThankYou";
import PaintProtectionFilm from "./page/seo/PaintProtectionFilm";
import CeramicCoatingServices from "./page/ceramic-coating/CeramicCoatingServices";
import StoreLogin from "./page/StoreManagement/StoreLogin";
import  StoreManagement  from "./page/StoreManagement/StoreManagement";
import MyStoreOrder from "./page/StoreManagement/MyStoreOrder";
import CeramicCoatingServicesGgn from "./page/Gurugram/ceramic-coating-ggn/CeramicCoatingServicesGgn";
import PaintProtectionFilmGgn from "./page/Gurugram/paint-protection-film-ggn/PaintProtectionFilmGgn";
import CeramicCoatingServicesDelhi from "./page/Delhi/ceramic-coating-delhi/CeramicCoatingServicesDelhi";
import PaintProtectionFilmDelhi from "./page/Delhi/paint-protection-film-delhi/PaintProtectionFilmDelhi";
import CarDetailingServices from "./page/car-detailing/CarDetailingServices";
import CarDetailingServicesGgn from "./page/Gurugram/car-detailing-ggn/CarDetailingServicesGgn";
import CarDetailingServicesDelhi from "./page/Delhi/car-detailing-delhi/CarDetailingServicesDelhi";
import CarPpfExpert from "./carPpfexpert/CarPpfExpert";


function App() {
  const location = useLocation();
  const noNavbarFooterRoutes = ['/store-login', '/store-management','/my-store-order'];

  const shouldHideNavbarFooter = noNavbarFooterRoutes.includes(location.pathname);

  return (
    <>
      {!shouldHideNavbarFooter && <HandleNavbar />}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/enter-otp" element={<EnterOTP />} />
        <Route
          path="/my-details"
          element={<PrivateRoute Component={<EditDetails />} />}
        />
        <Route
          path="/my-cars"
          element={<PrivateRoute Component={<MyCars />} />}
        />
        <Route
          path="/my-orders"
          element={<PrivateRoute Component={<MyOrders />} />}
        />

        {/* <Route path="/my-orders/:id" element={<PrivateRoute Component={MyOrderChild} />}/> */}

        <Route
          path="/my-account"
          element={<PrivateRoute Component={<MyAccount />} />}
        />

        <Route path="/services" element={<Services />}>
          <Route path="/services/:id" element={<ServiceCard />} />
        </Route>

        <Route
          path="/my-coupons"
          element={<PrivateRoute Component={<MyCoupons />} />}
        />
        <Route
          path="/order-placed-successfully"
          element={<PrivateRoute Component={<OrderPlacedSuccessfully />} />}
        />
        <Route
          path="/maintenance"
          element={<PrivateRoute Component={<Maintenance />} />}
        />
        <Route
          path="/address"
          element={<PrivateRoute Component={<UserAddress />} />}
        />
        <Route
          path="/add-new-car"
          element={<PrivateRoute Component={<AddNewCar />} />}
        />
        <Route
          path="/cart"
          element={<PrivateRoute Component={<AddedCart />} />}
        />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/inspection" element={<InsPection />} />
        <Route path="/locator" element={<Locator />} />
        <Route path="/parking" element={<Parking />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/pre-delivery-inspection" element={<PreDelivery />} />
        <Route path="/rsa" element={<Rsa />} />
        <Route
          path="/enquiry-for-franchise"
          element={<EnquiryForFrenchise />}
        />
        <Route path="/workshop-locator" element={<Workshop />} />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/cancellation-and-refund-policy"
          element={<CancellationAndRefundPolicy />}
        />
        <Route
          path="/shipping-and-delivery-policy"
          element={<ShippingAndDeliveryPolicy />}
        />
        <Route path="/term-and-conditions" element={<TermAndCondition />} />
       
        {/* <Route path="/paint-protection-film" element={<LeadGeneration />} /> */}
        <Route path="/thank-you" element={<ThankYou />} />
        <Route
          path="/paint-protection-film"
          element={<PaintProtectionFilm />}
        />
        <Route
          path="/paint-protection-film-gurgaon"
          element={<PaintProtectionFilmGgn />}
        />
        <Route
          path="/paint-protection-film-delhi"
          element={<PaintProtectionFilmDelhi />}
        />

        <Route path="/ceramic-coating" element={<CeramicCoatingServices />} />
        <Route path="/ceramic-coating-gurgaon" element={<CeramicCoatingServicesGgn />} />
        <Route path="/ceramic-coating-delhi" element={<CeramicCoatingServicesDelhi/>} />
        <Route path="/car-detailing-services" element={<CarDetailingServices/>} />
        <Route path="/car-detailing-gurgaon" element={<CarDetailingServicesGgn/>} />
        <Route path="/car-detailing-delhi" element={<CarDetailingServicesDelhi/>} />
        <Route path="/car-ppf-experts" element={<CarPpfExpert/>} />

        {/* <Route path="/add-to-cart" element={<Login/>} /> */}
        <Route path="/store-login" element={<StoreLogin />} />
        <Route path="/store-management" element={<StoreManagement />} />
        <Route path="/my-store-order" element={<MyStoreOrder />} />
      </Routes>
      {!shouldHideNavbarFooter && <Footer />}
      <FloatingCart />
      {/* float cart toast */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
