import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const testimonials = [
  {
    name: "Vivek Sinha",
    image: "./men.jpg",
    text: "So recently I got PPF done on my TRIUMPH and the Experience I had with the “CAROSS” team is Amazing. They have done a great job specially on the tank as that is the trickiest thing to do in a bike because of lots of curves Thumbs up to the CAROSS Team !"
  },
  {
    name: "Vijay Bhalerao",
    image: "./men.jpg",
    text: "Got the PPF done for my new Kia Seltos. Had an amazing experience and very friendly staff.They explain everything properly and most of all, the prices are very reasonable. Quality an  finish of the work done is excellent. I would definitely visit again  Whenever I get another car and also will recommend Caross to everyone I know."
  },
  {
    name: "Jitender Nagar",
    image: "./men.jpg",
    text: "I have been to several car detailing studios,but across offers premium auto detailing services that are by far the best.the staff is professional, and they use high quality products. My car has never looked better.."
  },
  {
    name: "Sparsh Milakpuria",
    image: "./men.jpg",
    text: "I recently visited Caross Detailing Gurgaon studio for a PPF application on my car, and I couldn't be more satisfied with their service. The staff were highly trained and provided excellent suggestions for the best PPF quality suited for my car. Their service was top-notch, and the hospitality was outstanding. I highly recommend for anyone looking  quality car detailing and exceptional customer service. And the best part is they are not charging very high like others and not showing big pictures they are very genuine and providing the  best for your car."
  },
  {
    name: "Pushkar Singh",
    image: "./men.jpg",
    text: "I went here for PPF service and experienced a thorough  and comfortable application. They give  you honest advice on the kind of PPF you should go ahead with. You can  look forward to them  as your trusted car detailer"
  },
  {
    name: "Vikash Kumar",
    image: "./men.jpg",
    text: "Highly satisfied with the service. The team did a  fabulous job for my Hyundai Creta Car. Team  increased the beauty of my car after PPF. Team is very humbled and  professional.Highly  recommended for PPF in Gurgaon!."
  },
  {
    name: "Himanshu Jain",
    image: "./men.jpg",
    text: "Had an amazing experience with Caross they were truly professionals. I was in doubt initially whether to go for ppf or not but after their services I would recommend everyone to go for it. Shout out to Dev and Sourabh for being very cooperative during the whole process."
  },
  {
    name: "Vishal Chawla",
    image: "./men.jpg",
    text: "Professional and friendly staff, they totally changed my car look , my car looks like brand new with shine , I'm totally satisfied with their work , I highly recommend to all, Caross detailing is best👌"
  },
  {
    name: "Aman Bhutani",
    image: "./men.jpg",
    text: "The team is professional, friendly, and goes the extra mile to ensure customer satisfaction. From routine maintenance to meticulous detailing, they've got it all covered. The prices are reasonable, and the transparency in their work is commendable. I highly recommend Caross for anyone seeking top-notch car care. Trustworthy, efficient, and reliable – they truly know how to pamper your vehicle. Five stars all the way!"
  },
  {
    name: "Madhukar Sharma",
    image: "./men.jpg",
    text: "I recently visited Caross for a thorough car detailing service, and I must say that my experience was nothing short of exceptional. From the moment I drove into their facility to the final inspection of my vehicle, every aspect of their service exceeded my expectations."
  }
];

const TestimonialSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };

  return (
    <div className="testimonial-slider-main-section container mt-5">
      <div className="text-center mb-4">
        <h2 className="text-danger">Hear it directly from our customers!</h2>
      </div>
      <Slider {...settings} className="testimonial-slider">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="px-2 py-4">
            <div className="card shadow-sm p-3 border-1 rounded-4 w-100">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="rounded-circle mx-auto d-block mb-3"
                style={{ width: "60px", height: "60px", objectFit: "cover" }}
              />
              <div className="card-body text-center">
                <h5 className="card-title">{testimonial.name}</h5>
                <p className="card-text">  {testimonial.text.length > 150 ? `${testimonial.text.substring(0,150)}...` : testimonial.text}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
