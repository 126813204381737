import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const carProtectionItems = [
  { imgSrc: "icon/Key scratches.png", label: "Key scratches" },
  { imgSrc: "icon/Rock chips2.png", label: "Rock chips" },
  { imgSrc: "icon/Door dings.png", label: "Door dings" },
  { imgSrc: "icon/Paint-scuffs.png", label: "Paint scuffs" },
  { imgSrc: "icon/Water-spotting-rust---Copy.png", label: "Water spotting rust" },
  { imgSrc: "icon/Paint-fade---Copy.png", label: "Paint fade" },
  { imgSrc: "icon/Bird-droppings.png", label: "Bird droppings" },
  { imgSrc: "icon/Bug-splatter.png", label: "Bug splatter" },
];

const CarProtectionSection = () => {
  return (
    <div style={{ backgroundColor: " #b8242a" }}>
    <div className="container p-5 ">
      <div className="text-center mb-4">
        <h2 className="d-inline-block bg-dark text-white py-2 px-3 position-relative">
          COMPLETE CAR <span className="text-danger">PROTECTION AGAINST</span>
        </h2>
      </div>
      <div className="row g-4">
        {carProtectionItems.map((item, index) => (
          <div key={index} className="col-6 col-md-4 col-lg-3 d-flex justify-content-center">
            <div className="text-center">
              <img src={item.imgSrc} alt={item.label} className="img-fluid" style={{ maxWidth: "80px", height: "auto" }} />
              <p className="text-white mt-2 fs-5">{item.label}</p>
            </div>
          </div>
        ))}
      </div>
      
    </div>
    </div>
  );
};

export default CarProtectionSection;
