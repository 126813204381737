import React from 'react';

const Card = ({ img, title, text, link }) => {
  console.log(img, title, text, link);
  return (
    <div className="col-md-6 col-lg-3 mb-4">
      <div className="card shadow-sm  w-100 p-2" style={{maxHeight:"400px",height:"100%"}}>
        <div style={{ height: '250px' }}>
          <img
            src={img}
            className="card-img-top"
            alt={title}
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          {link ? (
            <a href={link} className="card-link" target="_blank" rel="noopener noreferrer">
              FindStore
            </a>
          ) : (
            <p className="card-text">{text}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
