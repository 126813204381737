import React from "react";
// import TitleName from "./TitleName";

const PPFaq = () => {
  return (
    <div className="container mt-3 mb-4">
    <h1 className="text-center mb-4">Frequently Asked Questions</h1>
      <div className="accordion " id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h3 className="fs-5">1. Why do I need PPF for my car?</h3>
            </button>
          </h3>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              Paint protection film can help you preserve the original colour and shine of your
                                    car’s paint, as
                                    well as prevent fading, oxidation and corrosion. PPF can also enhance the appearance
                                    and
                                    durability of your car’s paint, as well as increase the resale value of your car.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <h3 className="fs-5">2. What are the types of PPF available in the market?</h3>
            </button>
          </h3>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">There are different types of PPF products available in the market, such as gloss,
                                    matt, coloured,
                                    clear or thick. Each type of PPF has different features, finishes and thicknesses to
                                    suit your car’s
                                    needs and preferences.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h3 className="fs-5">
              3. How is PPF applied to my car?
              </h3>
            </button>
          </h3>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              PPF is applied to your car by trained and professional installers who use special
                                    tools and
                                    techniques to ensure a smooth and flawless installation. The PPF is cut and shaped
                                    according
                                    to the contours of your car and then adhered to the surface with a special adhesive.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">4. How long does PPF last on my car?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              PPF can last for years on your car, depending on the quality of the product, the
                                    installation and
                                    the maintenance. PPF products usually come with a warranty that covers defects and
                                    damages
                                    for a certain period.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">5. How do I maintain PPF on my car?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              PPF is easy to maintain, as it is resistant to stains, dirt and dust. You can wash
                                    and wax your car
                                    as usual, but avoid using abrasive or harsh chemicals that can damage the film. You
                                    can also
                                    use a microfiber cloth to wipe off any minor scratches or marks on the film.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">6. Is PPF visible on the car?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              PPF is designed to be almost invisible. High-quality PPF enhances the car’s gloss or
                                    can even
                                    provide a matte finish, depending on the film type.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">7. Will PPF turn yellow over time?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              High-quality PPFs resist yellowing, but prolonged exposure to UV rays and low-quality
                                    products
                                    can cause discoloration.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">8. Can PPF be removed?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              Yes, PPF can be safely removed without damaging the car’s original paint, provided
                                    it's done by
                                    professionals.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">9. Is PPF worth the investment?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              PPF is a valuable investment for protecting your car’s paint, maintaining its resale
                                    value, and
                                    reducing the need for frequent touch-ups.
                                    10. What happens if the PPF gets damaged?
                                    Damaged PPF can be replaced for the affected section without reapplying the entire
                                    film.
                                    Professional installers can handle the repair seamlessly
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PPFaq;
