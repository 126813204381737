import React from "react";
import mainVideo from "../asets/main-video.mp4";
import premiumImage from "../asets/Premium Protection for Long Lasting Shine.png";
import blackImage from "../asets/BLACK-WITH VIDEO.mp4";
import aerocar from "../asets/aero-car.png";
import ImageTextSection from "./ImageTextSection";
import CarProtectionSection from "./CarProtectionSection";
import PromoSection from "./PromoSection";
import PpfAdvantages from "./PpfAdvantages";
import PPFSection from "./PPFSection";
import PPFTable from "./PPFTable";
import CardSection from "./CardSection";
import HowWorkSection from "./HowWorkSection";
import TestimonialSlider from "./TestimonialSlider";
import PPFaq from "./PPFaq";
import ServicesSection from "./ServicesSection";
import ServiceWorkSection from "./ServiceWorkSection";
import NewsletterSection from "./NewsletterSection";
import "./common.css";




const CarPpfExpert = () => {
  return (
    <>
<div className="video-main-hero-section vh-100">
  <div className="video-hero-home position-relative w-100 h-100">
    <div className="video-overlay position-absolute w-100 h-100 bg-dark opacity-50"></div>
    <video
      className="w-100 h-100 position-absolute top-0 start-0 object-fit-cover"
      muted
      loop
      autoPlay
    >
      <source src={mainVideo} type="video/mp4" />
    </video>
    <div className="video-content position-absolute top-50 start-50 translate-middle text-white text-center px-3 w-100">
      <h2 className="display-3 fw-bold">Car PPF Experts</h2>
      <p className="fs-4 text-white">
        Protect your car like never before with Caross Detailing Studios.
      </p>
      <a
              href="https://wa.me/+918929227077"
              target="_blank"
              rel="noopener noreferrer"
              className="btn d-inline-flex align-items-center px-4 py-3 mt-2"
              style={{ backgroundColor: " #b8242a", color: "white" }}
            >
        Connect with us now!
     
      </a>
    </div>
  </div>
  <div className="video-main-hero-content text-center p-4">
    <h2 className="text-danger display-5 fw-bold">
      Best Paint Protection Film Services in India
    </h2>
    <p className="fs-5 text-dark">
      Protect your car from scratches and damages with the best paint protection film in India.
    </p>
  </div>
</div>



      <div className="container py-5">
        <div className="text-center">
          <div className="mb-4">
            <img
              src={premiumImage}
              alt="Premium Protection for Long Lasting Shine"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="text-center my-4">
          <img
            src="https://www.garwarepolyesterfilms.com/wp-content/uploads/2021/07/Garware-Hi-Tech-Films.png"
            alt="Brand Logo"
            className="img-fluid"
          />
        </div>
        <div className="row align-items-center justify-content-center g-4">
          <div className="col-md-6 border border-2 p-4  border-danger">
            <video
              autoPlay
              loop
              muted
              playsInline
              className="w-100 "
              style={{ objectFit: "contain" }}
            >
              <source src={blackImage} type="video/mp4" />
            </video>
          </div>
          <div className="col-md-6">
            <img src={aerocar} alt="Aero Car" className="img-fluid" />
          </div>
        </div>
      </div>
      <ImageTextSection />
      <CarProtectionSection/>
      <PromoSection title="Protect your car with Garware PPF, at the best possible price in India." btnText="connect with us"/>
      <PpfAdvantages/>
      <PPFSection/>
      <PPFTable/>
      <CardSection/>
      <HowWorkSection/>
      <TestimonialSlider/>
      < PPFaq/>
      <ServicesSection/>
      <ServiceWorkSection/>
      <PromoSection title="Contrary to popular belief Lorem Ipsum is not simply random text" btnText="Enquire Now" />
      <NewsletterSection/>
    </>
  );
};

export default CarPpfExpert;
