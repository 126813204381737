import React from 'react';
import REDCAR2 from '../asets/REDCAR2.png';

const ImageTextSection = () => {
  return (
    <div className="bg-light py-5"> {/* Background color and padding */}
      <div className="container"> {/* Container for max-width and centering */}
        <div className="row g-4 align-items-center"> {/* Grid with gap and alignment */}
          {/* Text Content Section */}
          <div className="col-md-6 text-center text-md-start">
            <h2 className="display-4 fw-bold mb-4">Why choose Caross for PPF?</h2>
            <p className="lead mb-4">
              At Caross, we offer top-quality PPF solutions tailored to protect your car's paint with unmatched durability. With expert application and premium products like Garware PPF, we ensure a flawless finish and long-lasting protection. Choose us for trusted, high-quality car care services that keep your vehicle looking new.
            </p>
            {/* WhatsApp Button */}
            <a
              href="https://wa.me/+918929227077"
              target="_blank"
              rel="noopener noreferrer"
              className="btn d-inline-flex align-items-center px-4 py-3"
              style={{ backgroundColor: " #b8242a", color: "white" }}
            >
              Connect on WhatsApp
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp Icon"
                className="ms-2"
                style={{ width: '20px', height: '20px' }}
                
              />
            </a>
          </div>

          {/* Image Section */}
          <div className="col-md-6 text-center">
            <img
              src={REDCAR2}
              alt="Car with PPF"
              className="img-fluid rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageTextSection;