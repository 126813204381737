import React from 'react';


const PromoSection = ({title , btnText}) => {
  btnText = btnText.toUpperCase()
  const myTitle = title.split(",")
  console.log(myTitle)

  return (
    <div className="image-with-text-main-section">
      <div className="d-flex justify-content-center align-items-center" style={{ backgroundImage: 'url(./design.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="container text-center py-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="promo-section py-5">
                <p className="promo-text fs-1 fw-bold text-white mb-4 p-4 text-capitalize">
                  {myTitle[0]} <span className="highlights  "style={{ color: "rgb(224, 26, 33)",textDecorationLine:"underline"}} >{myTitle[1]}</span>
                </p>
                <a
              href="https://wa.me/+918929227077"
              target="_blank"
              rel="noopener noreferrer"
            
            >
                <button key="promo-button" className="promo-button btn btn-light text-capitalize text-dark px-5 py-3 fw-bold rounded shadow-sm">{btnText}</button>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoSection;
