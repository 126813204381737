import React from "react";

const PPFSection = () => {
  return (
    <div className="container py-5">
      
      
      <div className="row mt-5">
        <div className="col-md-12 text-center">
          <h2>Types of PPF available at Caross Detailing Studios</h2>
          <p>
            At Caross, we offer an array of paint protection films developed by the most trusted brands in
            India that safeguard your car’s paint from scratches, stains, UV damage, and environmental wear.
          </p>
        </div>
      </div>

      {[{
        title: "Garware Premium PPF",
        description: "Garware Premium PPF provides exceptional protection for your car with a 5-year assurance. Featuring self-repairing technology, it maintains a shiny, clean look while offering water resistance and a perfect tailored fit to safeguard your car’s paint from everyday wear and tear.",
        image: "./Garware.png"
      }, {
        title: "Garware Plus Paint Protection Film",
        description: "Garware Plus Paint Protection Film comes with a 3-year warranty, offering reliable protection for your car’s paint. It safeguards against scratches, stains, and minor damages, ensuring your car retains its glossy, pristine look.",
        image: "./Garware.png"
      }, {
        title: "Paintguard Professional Paint Protection Film",
        description: "Paintguard Professional Paint Protection Film is a premium solution of American origin with a robust 200-micron thickness. It offers 5 years of extended assurance, advanced heat-activated self-healing, and exceptional durability under high temperatures.",
        image: "./Paintguard.png"
      }, {
        title: "Paintguard Standard Paint Protection Film",
        description: "Paintguard Standard Paint Protection Film offers reliable protection with American-origin material and a durable 170-micron thickness. It features self-healing properties, high-temperature resistance, and strong physical durability, backed by a 2-year warranty for dependable performance.",
        image: "./Paintguard.png"
      }, {
        title: "Paintguard Standard 2 Yr",
        description: "Paintguard Standard 2 Yr provides reliable car paint protection with American-origin material, 170-micron thickness, self-healing features, and a 2-year warranty. Designed for durability, it offers strong physical properties to safeguard your vehicle's finish effectively.",
        image: "./Paintguard.png"
      }, {
        title: "Garware Premium 8 Yr",
        description: "Garware Premium 8 Yr PPF offers long-lasting protection with an 8-year warranty. Featuring 200 microns thickness and self-healing technology, it ensures a high-gloss finish, UV, and chemical resistance.",
        image: "./Garware.png"
      }].map((item, index) => (
        <div key={index} className={`row align-items-center my-4 p-5 rounded-4 border border-2 ${index % 2 === 0 ? 'bg-white' : ' text-white'}`} style={{ backgroundColor: index % 2 !== 0 ? '#b8242a' : '' }}>
          <div className={`col-md-6 ${index % 2 === 0 ? "order-md-1" : "order-md-2"}` }>
            <h2>{item.title}</h2>
            <p  className={` mt-2 mb-2 ${index % 2 === 0 ? "text-dark" : "text-white"}` }>{item.description}</p>
            <a href="https://wa.me/+918929227077" target="_blank" rel="noreferrer" className="btn d-inline-flex align-items-center mb-2 mt-2" style={{ backgroundColor: "  #25d366", color: "white" }}>
              Connect on WhatsApp
              <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp Icon" className="ms-2" width="20" />
            </a>
          </div>
          <div className={`col-md-6 text-center border border-3 ${index % 2 === 0 ? "order-md-2 border-danger" : "order-md-1"}` }>
            <img src={item.image} alt={item.title} className="img-fluid" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PPFSection;
