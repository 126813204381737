import React from 'react';
import Card from './Card';


const ServiceWorkSection = () => {
    const cardData = [
        {
            img: "./Gurugram (1).jpg",
            title: "GURUGRAM",
            link: "https://g.co/kgs/m1yJ6bf"
        },
        {
            img: "./Gurugram (2).jpg",
            title: "PASCHIM VIHAR",
            link: "https://g.co/kgs/cTzSkhB"
        },
        {
            img: "./Gurugram (3).jpg",
            title: "SAKET",
            link: "https://g.co/kgs/G8WjT3b"
        }
    ];

    return (
        <div style={{ backgroundColor: " #b8242a"}}>
        <div className="container-fluid py-5">
          <div className="container  text-white text-center">
            <h2>Our Studios.</h2>
          
            <div className="row mt-4">
              {cardData.map((card, index) => (
                <Card key={index} img={card.img} title={card.title}  link={card.link} />
              ))}
            </div>
          
          </div>
        </div>
        </div>
    );
};

export default ServiceWorkSection;
