import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const PPFTable = () => {
  const ppfData = [
    {
      name: "Garware Premium PPF",
      warranty: "5-Year Assurance",
      features: "Self-Repairing, Shiny and Clean, Water-Resistant",
    },
    {
      name: "Garware Plus Paint Protection Film",
      warranty: "3-Yr Warranty",
      features: "Self-Repairing, Glossy Finish, Durable Build",
    },
    {
      name: "Paintguard Professional Paint Protection Film",
      warranty: "5-Yr Extended Assurance",
      features: "Advanced Heat-Activated",
    },
    {
      name: "",
      warranty: "",
      features: "Self-Healing, 200-Micron Thickness, Premium American Origin",
    },
    {
      name: "Paintguard Standard Paint Protection Film",
      warranty: "2-Yr Assured Warranty",
      features: "American-Origin Material, 170 Microns Thickness, High-Temperature Resistance",
    },
    {
      name: "Garware Premium 8 Yr",
      warranty: "8-Yr Warranty for Long Lasting",
      features: "200 Microns thickness, High Gloss Finish, UV & Chemical Resistance",
    },
    {
      name: "Oneshield PPF",
      warranty: "5-Yr Comprehensive Warranty",
      features: "High Impact Resistance, UV & Hydrophobic, Easy Maintenance",
    },
  ];

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">PPF Protection Films</h2>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="bg-success text-white">
            <tr>
              <th>PPF Name</th>
              <th>Warranty</th>
              <th>Top Features</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {ppfData.map((item, index) => (
              <tr key={index}>
                <td className="fw-bold">{item.name}</td>
                <td>{item.warranty}</td>
                <td>{item.features}</td>
                <td>
                  <a
                    href="https://wa.me/+918929227077"
                
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chat on WhatsApp
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PPFTable;
