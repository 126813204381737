import React from "react";
import top3 from "../asets/top3.png";
import REDPPF from "../asets/REDPPF.png";


const cardData = [
  {
    img: "icon/Warranty (1).png",
    title: "Comprehensive Warranty",
    description:
      "Enjoy warranties ranging from 3 to 10 years, ensuring reliable protection with every PPF option.",
  },
  {
    img: "icon/Impact-Resistance.png",
    title: "Enhanced Durability",
    description:
      "Crafted with high-quality materials, Caross PPF resists yellowing, peeling, and cracking for lasting performance.",
  },
  {
    img: "icon/Durability (1).png",
    title: "Advanced UV Defense",
    description:
      "Effectively blocks UV rays, preventing paint fading and preserving your car’s vibrant appearance.",
  },
  {
    img: "icon/Self-Healing (1).png",
    title: "Optimal Thickness",
    description:
      "With options between 150 and 300 microns, our PPF offers exceptional thickness for superior paint protection.",
  },
  {
    img: "icon/UV-Protection.png",
    title: "Superior Impact Shield",
    description:
      "Protects against chips and scratches from flying debris like gravel, ensuring your car’s surface stays flawless.",
  },
  {
    img: "icon/Weather-Resistance.png",
    title: "Innovative Self-Healing",
    description:
      "The nano-based coating allows minor scratches to self-repair with heat while enhancing hydrophobic properties.",
  },
  {
    img: "icon/Thickness (1).png",
    title: "Weather-Resistant Design",
    description:
      "Engineered to withstand India’s harsh weather, providing consistent protection in any climate.",
  },
  {
    img: "icon/Thickness (1).png",
    title: "Flawless Installation",
    description:
      "Our expert team ensures a bubble-free application with seamless edges for a polished look.",
  },
  {
    img: "icon/Thickness (1).png",
    title: "Trusted Quality",
    description:
      "Backed by reputable brands like Garware, Caross PPF ensures top-tier protection for your vehicle.",
  },
];

const PpfAdvantages = () => {
  return (
    <div style={{ backgroundColor: " #b8242a" }}>
    <div className="container py-5 text-white" >
      <div className="text-center mb-4">
        <h2>
          ADVANTAGES OF GETTING <span className="text-warning">PPF FROM CAROSS</span>
        </h2>
        <p className="text-white">Below are the advantages of going ahead with PPF from Caross Detailing Studios.</p>
      </div>

      <div className="text-center mb-4">
        <img src={top3} alt="Top view of PPF advantages" className="img-fluid" />
      </div>

      <div className="row g-4">
        {cardData.map((card, index) => (
          <div className="col-md-4" key={index}>
            <div className=" text-center p-3">
              <img src={card.img} alt={card.title} className="card-img-top mx-auto" style={{ width: "80px" }} />
              <div className="card-body">
                <h5 className="card-title">{card.title}</h5>
                <p className="card-text text-white">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center mt-4">
      <a
              href="https://wa.me/+918929227077"
              target="_blank"
              rel="noopener noreferrer"
            
            >
        <button className="btn btn-light px-5 py-2">Enquire Now</button>
        </a>
      </div>
    </div>
    <div className="container py-5">
      <div className="row align-items-center">
        <div className="col-md-6 text-center">
          <img src={REDPPF} alt="PPF Thickness" className="img-fluid" />
        </div>
        <div className="col-md-6 text-start text-white">
          <p className="text-white fs-5">
            Thickness of PPF plays a crucial role in its effectiveness, as it
            directly impacts the level of protection it provides. A thicker
            PPF, ranging from 150 to 300 microns, offers superior resistance
            against scratches, stone chips, and minor abrasions. It also
            enhances the film's durability, ensuring long-lasting defense
            against wear and tear.
          </p>
          <a
              href="https://wa.me/+918929227077"
              target="_blank"
              rel="noopener noreferrer"
          
            >
          <button className="btn btn-light mt-3">Enquire Now</button>
          </a>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PpfAdvantages;
